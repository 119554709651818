import React, { ReactNode } from 'react'
import {} from 'react-router-dom'

// Layout import
import Layout from '@/components/Layout'

export interface routerType {
  path: string
  component: ReactNode
  children?: routerType[]
}

export const ignorePath = ['/login']
export const ignoreAuthPath = ['/login', '/', '/course', '/board', '/my', '/works', '/t']

const router: routerType[] = [
  {
    path: '/',
    component: <Layout />,
    children: [
      {
        path: '/',
        component: '../pages/home/index.tsx'
      },
      {
        path: '/course',
        component: '../pages/course/index.tsx'
      },
      {
        path: '/board',
        component: '../pages/board/index.tsx'
      },
      {
        path: '/my',
        component: '../pages/my/index.tsx'
      },
      {
        path: '/works',
        component: '../pages/works/index.tsx'
      },
      {
        path: '/order',
        component: '../pages/order/index.tsx'
      },
      {
        path: '/t',
        component: '../pages/t/index.tsx'
      },
      {
        path: '*',
        component: '../pages/error/auth/index.tsx'
      }
    ]
  },
  {
    path: '/login',
    component: '../pages/login/index.tsx'
  }
]

export default router
