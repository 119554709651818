import React, { useEffect, useState } from 'react'
import { AnimatedRoutes } from './router'
import { ThemeProvider } from '@emotion/react'
import { darkTheme, lightTheme } from './theme'
import { isDarkState } from './store/theme'
import { Provider, useAtom, useAtomValue } from 'jotai'
import './preflight.css'
import './index.css'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { myStore } from '@/store/global'
import eruda from 'eruda'
import { ToastContainer } from 'react-toastify'
dayjs.locale('zh-cn')

export default function Index() {
  const isDark = useAtomValue(isDarkState)

  useEffect(() => {
    console.info(`%c Version %c ${import.meta.env.VITE_APP_TIME} `, 'color: #fff; background: #5f5f5f', 'color: #fff; background: #4bc729')
    if (import.meta.env.VITE_APP_ENV !== 'live') {
      eruda.init()
    }
  }, [])

  return (
    <Provider store={myStore}>
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
        <AnimatedRoutes />
      </ThemeProvider>
      <ToastContainer stacked className="toast-container text-[16px]" toastClassName="toast-class" bodyClassName="toast-body-class" />
      {import.meta.env.VITE_APP_ENV !== 'live' ? <div className="fixed z-[9999] top-0 left-0 bg-[#4bc729] px-[4px] text-white text-[20px]">{import.meta.env.VITE_APP_TIME}</div> : null}
    </Provider>
  )
}
